<template>
  <div>
    <div
      v-if="loading !== 'error'"
    >
      <div
        v-if="loading"
        class="d-flex align-items-center justify-content-center"
        style="height: 80vh"
      >
        <b-spinner variant="primary" />
      </div>
      <div
        v-else
      >
        <back-navigation
          :title="editMode ? 'Edit Dry Report' : 'Tambah Dry Report'"
          :prevent-back="selectedQuotes.length > 0"
          @preventBack="$bvModal.show('preventBack')"
        />
        <b-modal
          id="preventBack"
          title="Ingin keluar?"
          centered
        >
          Yakin ingin keluar? Progress pengisian form anda belum disimpan.
          <template
            #modal-footer
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="$bvModal.hide('preventBack'); $router.go(-1)"
            >
              Ya, keluar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="$bvModal.hide('preventBack')"
            >
              Tetap Disini
            </b-button>
          </template>
        </b-modal>
        <validation-observer
          ref="validateDryReport"
        >
          <XyzTransition
            appear
            xyz="fade left-3 delay-2"
          >
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                  lg="6"
                  class="mb-1"
                >
                  <label>Lokasi</label>
                  <validation-provider
                    v-if="dryReport.locationOptions.length"
                    #default="{ errors }"
                    name="Lokasi"
                    rules="required"
                  >
                    <v-select
                      v-model="lokasi"
                      class="w-100"
                      label="name"
                      :options="dryReport.locationOptions"
                      placeholder="Lokasi"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <div
                    v-else
                    class="d-flex"
                  >
                    <b-skeleton
                      width="100%"
                      height="40px"
                    />
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                  class="mb-1"
                >
                  <label>Tanggal Mulai - Tanggal Selesai</label>
                  <validation-provider
                    v-if="dryReport.locationOptions.length"
                    #default="{ errors }"
                    name="Tanggal"
                    rules="required"
                  >
                    <date-range-picker
                      v-model="tanggal"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                  class="mb-1 mb-lg-0"
                >
                  <quote-dry-report-auto-suggest
                    :key="selectedQuotes.length"
                    class="mb-1"
                    :project-id="project.id"
                    @onSelected="handleSelectedQuote"
                    @resetQuote="resetDryReport"
                  />
                  <b-collapse :visible="project !== ''">
                    <div v-if="project">
                      <label class="d-block">Project</label>
                      <router-link
                        :to="{ name: 'project-detail', params: {id: project.id.toString()} }"
                        target="_blank"
                      >
                        <h6 class="m-0 text-primary d-inline">
                          {{ project.name }}
                        </h6>
                      </router-link>
                      <br>
                      <b-badge
                        variant="info"
                        class="mt-25"
                      >
                        {{ project.category.name }}
                      </b-badge>
                      <small class="text-secondary">
                        Tanggal Reminder : {{ formatDate(project.tgl_reminder) }}
                      </small>
                    </div>
                  </b-collapse>
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <label v-if="selectedQuotes.length">Quote Terpilih</label>
                  <vue-perfect-scrollbar
                    :settings="{
                      maxScrollbarLength: 200,
                    }"
                    class="scrollable-container media-list scroll-area"
                    :style="selectedQuotes.length > 2 && $store.state.app.windowWidth > 992 ? 'height: 180px' : null"
                  >
                    <b-card
                      v-for="(quote, index) in selectedQuotes"
                      :key="index"
                      no-body
                      border-variant="light"
                      class="mb-50 p-1"
                      :class="{ 'mr-1' : selectedQuotes.length > 2 && $store.state.app.windowWidth > 992 }"
                    >
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <div>
                          <router-link
                            :to="{ name: 'quotation-detail', params: {id: quote.id.toString(), version: quote.last_version.toString()} }"
                            target="_blank"
                          >
                            <h6 class="m-0 mb-25 text-primary">
                              {{ quote.name }}
                            </h6>
                          </router-link>
                          <small class="text-muted d-block">
                            Dibuat : {{ formatDate(quote.created_at) }} - Total : Rp. {{ shortNumber(quote.total) }}
                          </small>
                          <small class="text-muted">
                            Total Vol : {{ quote.total_volume }} L <span v-if="permission.viewHpp">- Total HPP : Rp. {{ shortNumber(quote.total_hpp) }}</span>
                          </small>
                        </div>
                        <div class="d-flex flex-column align-items-end">
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="text-secondary cursor-pointer d-block mb-25"
                            @click="deselectQuote(quote)"
                          />
                        </div>
                      </div>
                    </b-card>
                  </vue-perfect-scrollbar>
                </b-col>
              </b-row>
            </b-card>
          </XyzTransition>
          <XyzTransitionGroup
            appear
            class="item-group"
            xyz="fade stagger-1 left-3 delay-2"
          >
            <b-card
              v-if="dryReportInformation.areas"
              :key="1"
              title="Area"
            >
              <b-overlay :show="loadingDryReportInformation">
                <vue-good-table
                  :columns="areaColumns"
                  :rows="dryReportInformation.areas ? dryReportInformation.areas : []"
                  :sort-options="{ enabled: false }"
                  :pagination-options="{
                    enabled: false,
                  }"
                  style-class="vgt-table"
                >
                  <div slot="emptystate">
                    <small class="text-center d-block">Pilih quote terlebih dahulu</small>
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'area'">
                      <h6 class="m-0">
                        {{ props.row.category.name }}
                      </h6>
                    </span>
                    <span v-else-if="props.column.field === 'loss'">
                      <h6 class="m-0">
                        {{ props.row.loss ? `${props.row.loss} %` : '-' }}
                      </h6>
                    </span>
                    <span v-else-if="props.column.field === 'total_volume'">
                      <div class="d-flex">
                        <h6 class="m-0 mr-25">
                          {{ props.row.items.length ? props.row.items.map(el => el.liter).reduce((a, b) => a + b, 0) : 0 }} liter
                        </h6>
                        <feather-icon
                          v-if="props.row.items.length"
                          :id="`popover-${props.row.originalIndex}`"
                          icon="HelpCircleIcon"
                          size="16"
                          class="cursor-pointer"
                        />
                        <b-popover
                          :target="`popover-${props.row.originalIndex}`"
                          triggers="hover focus"
                        >
                          <table border="1">
                            <tr>
                              <th class="p-50">
                                Item
                              </th>
                              <th class="p-50">
                                Volume
                              </th>
                            </tr>
                            <tr
                              v-for="item in props.row.items"
                              :key="item.id"
                            >
                              <td class="p-50">
                                {{ item.item_name }}
                              </td>
                              <td class="p-50">
                                {{ item.liter }} L
                              </td>
                            </tr>
                          </table>
                        </b-popover>
                      </div>
                    </span>
                    <span v-else-if="props.column.field === 'quote_dft'">
                      <h6 class="m-0">
                        {{ props.row.quote_dft ? `${props.row.quote_dft} μm` : '-' }}
                      </h6>
                    </span>
                    <span v-else-if="props.column.field === 'real_dft'">
                      <validation-provider
                        #default="{ errors }"
                        :name="`Real DFT ${props.row.category.name}`"
                        rules="min_value:0|required|integer"
                      >
                        <b-input-group
                          append="μm"
                          class="input-group-merge"
                        >
                          <b-form-input
                            v-model="dryReportInformation.areas[props.row.originalIndex].real_dft"
                            type="number"
                            min="0"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </span>
                  </template>
                </vue-good-table>
              </b-overlay>
            </b-card>
            <b-card
              v-if="dryReportInformation.items"
              :key="2"
              title="Item"
            >
              <b-overlay :show="loadingDryReportInformation">
                <vue-good-table
                  :columns="itemColumns"
                  :rows="dryReportInformation.items ? dryReportInformation.items : []"
                  :sort-options="{ enabled: false }"
                  :pagination-options="{
                    enabled: false,
                  }"
                  style-class="vgt-table"
                >
                  <div slot="emptystate">
                    <small class="text-center d-block">Pilih quote terlebih dahulu</small>
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'item'">
                      <router-link
                        :to="{ name: 'item-detail', params: {id: props.row.item_id.toString()} }"
                        target="_blank"
                      >
                        <h6 class="text-primary m-0">{{ props.row.item_name }}</h6>
                      </router-link>
                      <small class="text-muted">{{ props.row.item_code }}</small>
                    </span>
                    <span v-else-if="props.column.field === 'color'">
                      <div
                        v-if="props.row.color"
                        class="d-flex align-items-center"
                      >
                        <div
                          v-b-popover.hover.top="'#'+props.row.color.hex_code"
                          class="colorswatch mr-50"
                          :style="`background-color: #${props.row.color.hex_code};`"
                        />
                        <div>
                          <h6 class="m-0">{{ props.row.color.eng_name }}</h6>
                          <small>{{ props.row.color.id_ral }}</small>
                        </div>
                      </div>
                    </span>
                    <span v-else-if="props.column.field === 'quote_liter'">
                      <h6 class="m-0">
                        {{ props.row.quote_liter }} liter
                      </h6>
                    </span>
                    <span v-else-if="props.column.field === 'real_liter'">
                      <validation-provider
                        #default="{ errors }"
                        :name="`Real Liter ${props.row.item_name}`"
                        rules="min_value:0|required|twodecimal"
                      >
                        <b-input-group
                          append="L"
                          class="input-group-merge"
                        >
                          <b-form-input
                            v-model="dryReportInformation.items[props.row.originalIndex].real_liter"
                            type="number"
                            min="0"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </span>
                    <span v-else-if="props.column.field === 'data_sheet'">
                      <b-img
                        v-if="props.row.data_sheet"
                        class="cursor-pointer mr-1"
                        :src="require('@/assets/images/icons/pdf.png')"
                        height="auto"
                        width="18"
                        title="Data Sheet"
                        @click="$refs.pdf.show(props.row.data_sheet)"
                      />
                    </span>
                  </template>
                </vue-good-table>
              </b-overlay>
            </b-card>
            <b-row
              v-if="selectedQuotes.length"
              :key="3"
            >
              <b-col
                cols="12"
                lg="6"
                offset-lg="6"
              >
                <b-card>
                  <div class="d-flex justify-content-between mb-1">
                    <div>
                      <h6 class="mb-0">
                        Upload File
                      </h6>
                      <small>Tambahkan file dry report disini</small>
                    </div>
                    <b-button
                      v-if="editMode && prevData.file"
                      size="sm"
                      variant="outline-danger"
                      @click="$refs.pdf.show(prevData.file)"
                    >
                      <b-img
                        class="cursor-pointer mr-25"
                        :src="require('@/assets/images/icons/pdf.png')"
                        height="auto"
                        width="12"
                        title="Data Sheet"
                      />
                      Preview
                    </b-button>
                  </div>
                  <vue-dropzone
                    id="dropzone"
                    ref="dropDataSheet"
                    :options="dropzoneOptions"
                    :use-custom-slot="true"
                    @vdropzone-error="dokumenError"
                    @vdropzone-file-added="handleFileAdded"
                  >
                    <div class="d-flex align-items-center justify-content-center h-100 pt-50">
                      <h6 class="text-muted font-weight-normal mt-2">
                        Upload PDF
                      </h6>
                    </div>
                  </vue-dropzone>
                  <b-row class="mt-1">
                    <b-col
                      cols="12"
                      lg="6"
                      class="pr-1 pr-lg-50 mb-1 mb-lg-0"
                    >
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline-secondary"
                        block
                        @click="resetDryReport"
                      >
                        Reset
                      </b-button>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="6"
                      class="pl-1 pl-lg-50"
                    >
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        block
                        :disabled="loadingAdd"
                        @click="loadingAdd ? null : submitForm()"
                      >
                        <b-spinner
                          v-if="loadingAdd"
                          variant="light"
                          small
                        />
                        <span v-else>Submit</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </XyzTransitionGroup>
        </validation-observer>
      </div>
    </div>
    <error-endpoint
      v-else
      :key="error"
      ref="error"
      :error="error"
    />
    <pdf-preview
      ref="pdf"
    />
  </div>
</template>

<script>
import BackNavigation from '@/components/misc/BackNavigation.vue'
import QuoteDryReportAutoSuggest from '@/components/input/QuoteDryReportAutoSuggest.vue'
import {
  BCard, BRow, BCol, BCollapse, BBadge, BFormInput, BInputGroup, BSkeleton,
  BButton, BOverlay, BSpinner, VBPopover, BImg, BModal, BPopover,
} from 'bootstrap-vue'
import VueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import moment from 'moment'
import { shortNumber } from '@core/utils/filter'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, minValue,
} from '@validations'
import DateRangePicker from '@/components/input/DateRangePicker.vue'
import vSelect from 'vue-select'
import { createHelpers } from 'vuex-map-fields'
import ErrorEndpoint from '@/components/misc/ErrorEndpoint.vue'
import PdfPreview from '@/components/misc/PdfPreview.vue'
import { mapGetters } from 'vuex'

const { mapFields } = createHelpers({
  getterType: 'getReportField',
  mutationType: 'updateReportField',
})

export default {
  components: {
    BackNavigation,
    QuoteDryReportAutoSuggest,
    BCard,
    BRow,
    BCol,
    VueDropzone,
    VuePerfectScrollbar,
    BCollapse,
    BOverlay,
    BBadge,
    BFormInput,
    BInputGroup,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    DateRangePicker,
    vSelect,
    BSkeleton,
    BButton,
    BSpinner,
    BImg,
    ErrorEndpoint,
    PdfPreview,
    BModal,
    BPopover,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      required,
      minValue,
      prevData: {},
      tanggal: {},
      lokasi: '',
      selectedQuotes: [],
      dryReportInformation: {},
      loadingDryReportInformation: false,
      error: '',
      loading: false,
      loadingAdd: false,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: true,
        acceptedFiles: '.pdf',
        autoProcessQueue: false,
        maxFilesize: 15,
        dictDefaultMessage: 'Taruh File Disini',
        uploadMultiple: false,
        maxFiles: 1,
        dictCancelUpload: 'Batalkan',
        dictCancelUploadConfirmation: 'Apakah anda yakin?',
        dictRemoveFile: 'Hapus File',
        dictFileTooBig: 'File terlalu besar ({{filesize}}MB). Maximal ukuran: {{maxFilesize}}MB.',
        dictInvalidFileType: 'Maaf, anda tidak bisa mengupload jenis file ini, hanya bisa .pdf',
        dictMaxFilesExceeded: 'Tidak boleh mengupload lebih dari 1 file. Hapus file sebelumnya terlebih dahulu.',
      },
      areaColumns: [
        {
          label: 'Area',
          field: 'area',
          sortable: false,
        },
        {
          label: 'Total Volume',
          field: 'total_volume',
          sortable: false,
        },
        {
          label: 'Quote DFT',
          field: 'quote_dft',
          sortable: false,
        },
        {
          label: 'Real DFT',
          field: 'real_dft',
          sortable: false,
          width: '130px',
        },
      ],
      itemColumns: [
        {
          label: 'Item',
          field: 'item',
        },
        {
          label: 'Warna',
          field: 'color',
        },
        {
          label: 'Quote Volume',
          field: 'quote_liter',
          width: '200px',
        },
        {
          label: 'Real Volume',
          field: 'real_liter',
          width: '130px',
        },
        {
          label: '',
          field: 'data_sheet',
          width: '80px',
        },
      ],
    }
  },
  computed: {
    project() {
      if (this.selectedQuotes.length) {
        return this.selectedQuotes[0].project
      }

      return ''
    },
    ...mapGetters({
      permission: 'getUserPermission',
    }),
    ...mapFields([
      'dryReport',
    ]),
    editMode() {
      return this.id && this.$route.name === 'edit-dryreport'
    },
  },
  mounted() {
    if (!this.dryReport.locationOptions.length) {
      this.$store.dispatch('getLocations').then(result => {
        this.dryReport.locationOptions = result
      })
    }

    if (this.editMode) {
      this.initForm()
    }
  },
  methods: {
    initForm() {
      this.loading = true
      this.$store.dispatch('getDryReportDetail', +this.id).then(data => {
        this.prevData = JSON.stringify(data)
        this.loading = false
        this.lokasi = data.location ? {
          city: data.location.city,
          id: data.location.id,
          name: data.location.name,
        } : ''
        this.tanggal = data.event_start ? {
          startDate: new Date(Date.parse(data.event_start)),
          endDate: new Date(Date.parse(data.event_end)),
        } : {}
        this.selectedQuotes = data.quotes
        this.dryReportInformation = {
          areas: data.areas.map(el => ({
            ...el,
            quote_dft: el.quote_data,
            real_dft: el.real_data,
          })),
          items: data.items.map(el => ({
            ...el,
            quote_liter: el.quote_data,
            real_liter: el.real_data,
          })),
        }

        if (data.file) {
          setTimeout(() => {
            const filename = data.file.substring(data.file.lastIndexOf('/') + 1)
            const file = {
              size: 1000 * 5000, name: filename, type: 'application/pdf', accepted: true,
            }
            this.$refs.dropDataSheet.manuallyAddFile(file, data.file)
          }, 500)
        }
      }).catch(error => {
        this.loading = 'error'
        this.error = `${error.graphQLErrors[0].message}`
      })
    },
    shortNumber,
    dokumenError(file, message) {
      if (message !== 'Upload canceled.') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    },
    handleSelectedQuote(data) {
      if (!this.selectedQuotes.some(el => el.id === data.id)) {
        this.selectedQuotes.push(data)

        this.getDryReportInformation()
      }
    },
    deselectQuote(data) {
      this.selectedQuotes = this.selectedQuotes.filter(el => el.id !== data.id)
      this.getDryReportInformation()
    },
    formatDate(date) {
      if (!date) {
        return ''
      }
      const parsedDate = Date.parse(date.split('.')[0])
      return moment(parsedDate).format('DD-MM-YYYY')
    },
    getDryReportInformation() {
      if (this.selectedQuotes.length) {
        this.loadingDryReportInformation = true
        const quoteList = `[${this.selectedQuotes.map(el => el.id).join(', ')}]`
        this.$store.dispatch('getDryReportInformation', quoteList).then(result => {
          this.dryReportInformation = {
            areas: result.areas.map(data => ({
              ...data,
              real_dft: 0,
            })),
            items: result.items.map(data => ({
              ...data,
              real_liter: 0,
            })),
          }
          this.loadingDryReportInformation = false
        }).catch(() => {
          this.loadingDryReportInformation = false
        })
      } else {
        this.dryReportInformation = {}
      }
    },
    resetDryReport() {
      this.$swal({
        title: 'Reset data form?',
        text: 'Konfirmasi jika anda ingin mereset form.',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // this.selectedQuotes = []
          // this.dryReportInformation = {}
          // this.tanggal = {}
          // this.lokasi = ''
          // this.$refs.validateDryReport.reset()
          const data = JSON.parse(this.prevData)
          this.loading = false
          this.lokasi = data.location ? {
            city: data.location.city,
            id: data.location.id,
            name: data.location.name,
          } : ''
          this.tanggal = data.event_start ? {
            startDate: new Date(Date.parse(data.event_start)),
            endDate: new Date(Date.parse(data.event_end)),
          } : {}
          this.selectedQuotes = data.quotes
          this.dryReportInformation = {
            areas: data.areas.map(el => ({
              ...el,
              quote_dft: el.quote_data,
              real_dft: el.real_data,
            })),
            items: data.items.map(el => ({
              ...el,
              quote_liter: el.quote_data,
              real_liter: el.real_data,
            })),
          }

          if (data.file) {
            setTimeout(() => {
              const filename = data.file.substring(data.file.lastIndexOf('/') + 1)
              const file = {
                size: 1000 * 5000, name: filename, type: 'application/pdf', accepted: true,
              }
              this.$refs.dropDataSheet.manuallyAddFile(file, data.file)
            }, 500)
          }
        }
      })
    },
    handleFileAdded() {
      const pdf = ['pdf', 'PDF']
      if (this.$refs.dropDataSheet.dropzone.files.length > 1) {
        this.$refs.dropDataSheet.removeFile(this.$refs.dropDataSheet.dropzone.files[0])
      }
      const filename = this.$refs.dropDataSheet.dropzone.files[0]?.upload?.filename.split('.')
      const format = filename?.length ? filename[filename.length - 1] : null
      if (!pdf.includes(format) && filename) this.$refs.dropDataSheet.removeFile(this.$refs.dropDataSheet.dropzone.files[0])
      if (this.$refs.dropDataSheet.dropzone.files[0]?.upload?.total >= 15000000) this.$refs.dropDataSheet.removeFile(this.$refs.dropDataSheet.dropzone.files[0])
    },
    async submitForm() {
      if (!this.tanggal.startDate || !this.tanggal.endDate) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Harap isi tanggal mulai dan tanggal selesai',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      } else if (this.$refs.dropDataSheet.getRejectedFiles().length) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Format file tidak didukung, harap hapus terlebih dahulu',
            icon: 'FileIcon',
            variant: 'danger',
          },
        })
      } else {
        this.$refs.validateDryReport.validate().then(success => {
          if (success) {
            this.loadingAdd = true
            const quoteList = this.selectedQuotes.map(el => el.id)
            const areas = this.dryReportInformation.areas.map(el => ({
              area_category: el.category.id,
              quote_dft: el.quote_dft,
              real_dft: +el.real_dft,
            }))
            const items = this.dryReportInformation.items.map(el => ({
              item_id: el.item_id,
              quote_liter: parseFloat(el.quote_liter),
              real_liter: parseFloat(el.real_liter),
            }))

            if (!this.editMode) {
              this.$store.dispatch('addDryReport', {
                quote: quoteList,
                file: this.$refs.dropDataSheet.getAcceptedFiles().length ? this.$refs.dropDataSheet.getAcceptedFiles()[0] : null,
                event_start: this.tanggal.startDate ? new Date(this.tanggal.startDate).toISOString().slice(0, 10) : null,
                event_end: this.tanggal.endDate ? new Date(this.tanggal.endDate).toISOString().slice(0, 10) : null,
                location_id: this.lokasi.id,
                areas,
                items,
              }).then(() => {
                this.loadingAdd = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Dry report berhasil ditambah!',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
                this.$store.state.report.dryReport.dryReportList = []
                this.$router.replace('/dryreport')
              }).catch(() => {
                this.loadingAdd = false
              })
            } else {
              const variables = {
                id: +this.id,
                event_start: this.tanggal.startDate ? new Date(this.tanggal.startDate).toISOString().slice(0, 10) : null,
                event_end: this.tanggal.endDate ? new Date(this.tanggal.endDate).toISOString().slice(0, 10) : null,
                location_id: this.lokasi.id,
                areas,
                items,
              }

              if (this.$refs.dropDataSheet.getAcceptedFiles().length && !this.$refs.dropDataSheet.getAcceptedFiles()[0].manuallyAdded) {
                // eslint-disable-next-line prefer-destructuring
                variables.file = this.$refs.dropDataSheet.getAcceptedFiles()[0]
              }
              this.$store.dispatch('updateDryReport', variables).then(() => {
                this.loadingAdd = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Dry report berhasil diupdate!',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
                this.$store.state.report.dryReport.dryReportList = []
                this.$router.replace('/dryreport')
              }).catch(() => {
                this.loadingAdd = false
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Harap lengkapi data terlebih dahulu',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '~@core/scss/base/bootstrap-extended/include';
// Overrides user variable
@import '~@core/scss/base/components/include';
.dropzone {
  padding: 0 !important;

  .dark-layout & {
    background: #283046;
  }
}
.vue-dropzone {
  border-radius: 4px;
  border: 1px solid #d8d6de;

  .dark-layout & {
    border: 1px solid #404656;
  }
}
.colorswatch {
  height: 36px;
  width: 36px;
  border-radius: 4px;
}
</style>
