<template>
  <vue-autosuggest
    v-model="item"
    :suggestions="filteredOptions"
    :input-props="inputProps"
    :limit="10"
    :component-attr-id-autosuggest="filteredOptions.length && filteredOptions[0].data[0] === 'empty' ? 'QuoteEmpty' : 'AutoSuggestEmpty'"
    @selected="onSelected"
    @input="onInputChange"
  >
    <template slot="before-suggestions">
      <div
        v-if="filteredOptions.length && filteredOptions[0].data[0] === 'empty'"
        class="d-flex justify-content-center align-items-center p-1"
        @mouseup.stop
      >
        <h6 class="text-muted text-center font-weight-normal m-0">
          Quote tidak ditemukan...
          <span v-if="projectId">
            Data quotes sedang difilter berdasarkan project dari quote yang dipilih,
          </span>
          <span
            v-if="projectId"
            class="text-primary cursor-pointer"
            @click="$emit('resetQuote')"
          >
            Reset Quote Terpilih?
          </span>
        </h6>
      </div>
    </template>
    <template slot="before-input">
      <div class="d-flex flex-wrap justify-content-between">
        <label>Cari Quote</label>
        <b-spinner
          v-if="loading && !error"
          class="mr-25"
          small
          variant="primary"
        />
        <label
          v-if="error"
          class="text-danger"
        >
          Data quote sedang bermasalah 😞
        </label>
      </div>
    </template>
    <template slot-scope="{suggestion}">
      <div
        v-if="filteredOptions.length && filteredOptions[0].data[0] !== 'empty'"
        class="cursor-pointer"
      >
        <h6 class="m-0 text-primary mt-25">
          {{ suggestion.item.name }}
        </h6>
        <small class="text-muted">
          {{ suggestion.item.project.name }}{{ suggestion.item.project.tgl_reminder ? ` - Tanggal Reminder : ${formatDate(suggestion.item.project.tgl_reminder)}` : null }}
        </small>
      </div>
    </template>
  </vue-autosuggest>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import { BSpinner } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { quoteDryReportSearch } from '@/graphql/query'

export default {
  components: {
    VueAutosuggest,
    BSpinner,
  },
  props: {
    projectId: {
      type: [Number, String],
      default: () => null,
    },
  },
  data() {
    return {
      loading: false,
      avatarText,
      item: '',
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: 'Cari quote...',
      },
      limit: 10,
      error: false,
    }
  },
  computed: {
    ...mapGetters({
      statusOptions: 'getStatusOptions',
    }),
  },
  mounted() {
    if (this.projectId) {
      this.fetchQuotes()
    }
  },
  methods: {
    onSelected(option) {
      if (option) {
        this.$emit('onSelected', option.item)
        this.item = ''
      }
    },
    onInputChange(text) {
      this.error = false
      if (text.length > 2) {
        if (this.timeout) {
          clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(async () => {
          await this.fetchQuotes(text)
        }, 1000)
      } else {
        this.filteredOptions = [{
          data: [],
        }]

        this.loading = false
      }
    },
    async fetchQuotes(text) {
      this.loading = true
      const project = this.projectId ? this.projectId : null
      const search = text ? `"${text.toLowerCase()}"` : null
      await this.$apollo.query({
        query: quoteDryReportSearch,
        context: {
          headers: {
            Authorization: this.$store.getters.getBearerToken,
          },
        },
        variables: {
          project_id: project,
          search,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        this.filteredOptions = [{
          data: result.data.quoteDryReportSearch.length ? result.data.quoteDryReportSearch : ['empty'],
        }]

        this.loading = false
      }).catch(() => {
        this.loading = false
        this.error = true
      })
    },
    formatDate(date) {
      if (!date) {
        return ''
      }
      const parsedDate = Date.parse(date.split('.')[0])
      return moment(parsedDate).format('DD-MM-YYYY')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';

#QuoteEmpty #autosuggest__results-item--0 {
  display: none !important;
}
</style>
